@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins Light";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins Thin";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Poppins-Thin.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  font-size: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  font-family: "Poppins Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #ffffff;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}

/* @media only screen and (max-width: 1000px) {
  html {
    font-size: 81.25%;
  }
} */

@media only screen and (max-width: 700px) {
  html {
    font-size: 62.5%;
  }
}

/* @media only screen and (max-width: 400px) {
  html {
    font-size: 43.75%;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
