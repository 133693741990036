.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 40%;
}

.contact form button {
  margin-top: 30px;
  margin-bottom: 40px;
  align-self: center;
}

.form-info {
  display: flex;
  justify-content: space-between;
}

.form-info,
.form-subject {
  height: 80px;
}

#form-name,
#form-email,
#form-subject,
#form-message {
  border-width: 2px;
  border-color: #3f415c;
}

.submit-message {
  color: red;
}

@media only screen and (max-width: 800px) {
  .contact form {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .form-info,
  .form-subject {
    height: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .contact form {
    width: 70%;
  }

  .form-info,
  .form-subject {
    height: 50px;
  }
}
