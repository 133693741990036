.home {
  position: relative;
  padding: 80px 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #24263b; */
  background-image: linear-gradient(#2c2c3ffa, #24263b);
}
