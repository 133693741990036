.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  transition: 0.3s ease;
  backdrop-filter: blur(10px);
}

.navbar-container.scrolled {
  background-color: #272738e6;
  height: 65px;
}

.navbar-content {
  position: relative;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.navbar-left {
  cursor: pointer;
}

.logo-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  color: var(--white);
}

.navbar-links {
  margin: 0px;
}

.navbar-links li {
  cursor: pointer;
  text-decoration: none;
  color: var(--white);
  display: inline-block;
  margin: 0px 15px;
  position: relative;
  font-size: 16px;
}

.navbar-links li::after {
  content: "";
  height: 3px;
  width: 0;
  background: var(--dark-orange);
  position: absolute;
  left: 50%;
  bottom: -8px;
  transition: 0.2s;
  transform: translateX(-50%);
}

.navbar-links li:hover::after {
  width: 100%;
}

.mobile,
.menu,
.wrapper {
  display: none;
}

@media only screen and (max-width: 700px) {
  .navbar-links li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 550px) {
  .mobile {
    display: flex;
    align-items: center;
  }

  .navbar-right {
    display: none;
  }

  .burger-menu {
    height: 30px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    z-index: 100;
  }

  .burger-bar {
    width: 100%;
    height: 5px;
    background-color: var(--white);
    border-radius: 2.5px;
  }

  .burger-bar.clicked:nth-child(1) {
    background-color: red;
    transform: rotate(45deg) translate3d(10px, 8px, 0);
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .burger-bar.clicked:nth-child(2) {
    width: 0;
    transform: translateX(50%);
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .burger-bar.clicked:nth-child(3) {
    background-color: red;
    transform: rotate(-45deg) translate3d(10px, -8px, 0);
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .burger-bar.unclicked:nth-child(1) {
    background-color: white;
    transform: rotate(0) translate3d(0, 0, 0);
    transition: 0.3s;
  }

  .burger-bar.unclicked:nth-child(2) {
    width: 100%;
    transition: 0.3s;
    transform: translateX(0);
  }

  .burger-bar.unclicked:nth-child(3) {
    background-color: white;
    transform: rotate(0) translate3d(0, 0, 0);
    transition: 0.3s;
  }

  .menu {
    display: unset;
    background-color: #2d2f3b;
    position: absolute;
    padding-top: 65px;
    padding-bottom: 20px;
    top: 0;
    right: 0;
    z-index: 50;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    border-bottom-left-radius: 20px;
  }

  .menu.hidden {
    width: 0%;
  }

  .menu.visible {
    width: 40%;
  }

  .menu-links {
    margin: 0;
    list-style: none;
  }

  .menu-links div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    text-decoration: none;
    /* text-align: center; */
    color: var(--white);
    padding: 20px;
    font-size: 16px;
    height: 30px;
  }

  .menu-links div:hover {
    background-color: #353544fa;
  }

  .menu-links div::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: white;
  }

  .wrapper {
    display: unset;
    background-color: #00000080;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 45;
  }

  .wrapper.hidden {
    width: 0%;
    height: 0;
  }

  .wrapper.visible {
    width: 100%;
    height: 100vh;
  }
}
