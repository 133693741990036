.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 65vh;
}

.about-container {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
}

.about-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 100%;
}

.about-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 100%;
  font-size: large;
  text-align: justify;
}

.img-container {
  position: relative;
  width: 80%;
  height: 80%;
  border-radius: 16px;
}

.img-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  z-index: 10;
  transition: 0.2s;
}

.img-container img:hover {
  transform: scale(1.05);
}

.img-container::before,
.img-container::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 16px;
  transition: 0.3s;
}

/* .img-container::before {
  background-color: rgb(58, 66, 76);
  transform: rotate(4.5deg) translateY(3px);
}

.img-container::after {
  background-color: rgb(66, 75, 87);
  transform: rotate(11deg) translateX(1px);
} */

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: #3f415c;
  color: #fff;
}

.skills-list {
  list-style: none;
}

.skills-list svg {
  margin: 15px;
  width: 40px;
  height: 40px;
}

/* @media only screen and (max-width: 1000px) {
  .about-left {
    height: 80%;
  }
} */

@media only screen and (max-width: 850px) {
  .about-container {
    flex-direction: column;
    justify-content: center;
  }

  .about-left {
    /* height: 300px; */
    display: none;
  }

  .about-right {
    width: 80%;
  }

  .about-right svg {
    margin: 5px;
    width: 35px;
    height: 35px;
  }
}

@media only screen and (orientation: portrait) and (max-width: 900px) {
  .about-container {
    justify-content: center;
  }

  .about-right {
    width: 90%;
  }

  .about-right svg {
    margin: 5px;
    /* width: 10px;
    height: 10px; */
  }
}

@media only screen and (max-width: 500px) {
  .about-right {
    font-size: small;
  }
}

@media only screen and (orientation: landscape) {
  .about {
    min-height: calc(100vh - 60px);
  }

  .about-container {
    height: calc(100vh - 60px - 55px);
  }
}
