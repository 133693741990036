:root {
  --light-blue: #04c3ff;
  --light-blue-shadow: rgb(69 211 255 / 27%);
  --sea-green: #0abead;
  --sea-green-dark: #0cbfae;
  --sea-green-extra-dark: #09bead;
  --sea-green-shadow: rgba(11, 190, 173, 0.4);
  --header-hamburger-background: rgba(11, 190, 173, 0.8);
  --orange: #f57f17;
  --dark-orange: #ff5823;
  --orange-shadow: rgba(245, 127, 23, 0.2);
  --white: #ffffff;
  --black: #333333;
  --disabled-btn: #09bead7a;
  --error-message: #e44f4fd1;
  --toast-error: #e44f4ffa;
  --toast-shadow: #9c9c9ce3;
  --toast-success: #0cbfae;
  --loader-color: #0cbfae;
}

#root {
  background-color: #ffffff;
}

body {
  background-color: #303042;
}

.btn {
  border-radius: 50px;
  padding: 14px 0;
  width: 160px;
  cursor: pointer;
  transition: 0.2s;
  font-family: "Poppins SemiBold";
}

.btn:active {
  transform: translateY(2px);
}

.primary-btn {
  color: var(--white);
  border: 2px solid linen;
  font-size: 12px;
  background-color: #1f2235;
}

/* @media only screen and (max-width: 414px) {
  .primary-btn {
    font-size: 13px;
  }
} */

.primary-btn:hover {
  color: aliceblue;
  border: 2px solid #ff5823;
  background-color: #1f2235;
}

.highlighted-btn {
  color: var(--white);

  font-size: 12px;
  background-color: #ff5823;
}

@media only screen and (max-width: 568px) {
  .highlighted-btn {
    z-index: -900;
  }
}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}
