.profile-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 30px;
}

.profile-parent {
  width: 450px;
  align-items: center;
  color: aliceblue;
}

.profile-details-name {
  font-size: 3.125rem;
  font-family: "Poppins Bold";
}

.profile-details-role {
  display: flex;
  flex-direction: column;
  margin: 14px 0 24px 0;
}

.profile-details-role div {
  font-size: 1.6rem;
  font-family: "Poppins Medium";
  font-style: italic;
  height: 100px;
}

.highlighted-text {
  color: #ff5823;
}

.profile-role-tagline {
  font-size: 1.5rem;
  margin: 5px 0 0 0;
  font-family: "Poppins Light";
}

.profile-options .btn {
  font-size: 1rem;
}

.profile-options .highlighted-btn {
  margin: 0 0 0 28px;
  border-radius: 12px solid white;
}

.profile-options .highlighted-btn:hover {
  background-color: cornsilk;
  color: #111;
}

.profile-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0 0 var(--white);
  border-radius: 50%;
  height: 380px;
  width: 380px;
}

.profile-picture-background img {
  height: 92%;
  width: 92%;
  object-fit: cover;
  border-radius: 50%;
  transition: 0.3s;
}

.profile-picture-background img:hover {
  transform: scale(1.07);
}

.colz .colz-icon i {
  font-size: 24px;
  color: white;
  padding: 5px;
  transition: 0.1s;
}

.colz .colz-icon i:hover {
  transform: scale(1.5);
}

.fa.fa-github:hover {
  color: #0d1017;
}

.fa.fa-linkedin-square:hover {
  color: #0b66c3;
}

.fa.fa-instagram:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa.fa-facebook-square:hover {
  color: #1877f2;
}

.fa.fa-youtube-play:hover {
  color: #ff0000;
}

@media only screen and (max-width: 850px) {
  .profile-picture {
    width: 240px;
    height: 240px;
  }
}

@media only screen and (max-width: 700px) {
  .profile-parent {
    width: 300px;
  }

  .proflie-details {
    margin: 25px 0 0;
  }

  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
  }

  .profile-details-role div {
    height: 60px;
  }
}

@media only screen and (orientation: portrait) {
  .profile-container {
    flex-direction: column-reverse;
  }
}
