.curve-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.curve-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
}

.curve-divider .shape-fill {
  fill: #FFFFFF;
}

@media only screen and (max-width: 1030px) {
  .curve-divider svg {
    height: 70px;
  }
}

@media only screen and (max-width: 850px) {
  .curve-divider svg {
    height: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .curve-divider svg {
    height: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .curve-divider svg {
    height: 40px;
  }
}
