.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
}

.projects-container {
  width: 90%;
}

.projects-row .col{
  margin-top: 30px;
}

.card {
  height: 100%;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
